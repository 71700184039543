<template>
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.8889 6.05458C14.8889 8.84614 12.558 11.1091 10 11.1091C7.44205 11.1091 5.11111 8.84614 5.11111 6.05458C5.11111 3.26302 7.44205 1.00001 10 1.00001C12.558 1.00001 14.8889 3.26302 14.8889 6.05458Z"
            stroke="white"
            stroke-width="2"
        />
        <path
            d="M18 18.6044C18 18.6044 16.6667 11.9972 14 12.8231C11.3333 13.649 8.66667 13.7867 6 12.8231C3.33333 11.8596 2 18.6044 2 18.6044C4.20995 21.7985 15.79 21.7985 18 18.6044Z"
            stroke="white"
            stroke-width="2"
        />
    </svg>
</template>
