<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="popup _review" @click="$emit('close')">
        <div class="popupInner" @click.stop>
            <btn-close @click="$emit('close')" />
            <div class="popup__body">
                <h2 class="popup__title">Оставьте ваш отзыв</h2>
                <div class="popup__raiting">
                    <div
                        class="popup__ech"
                        v-for="(star, index) in starLimit"
                        :key="index"
                        @click="setRatingStar(star)"
                        @mouseover="overRatingStar(star)"
                        @mouseleave="active = 0"
                    >
                        <img
                            :src="require(`@/assets/img/${active >= star || rating >= star ? ech_active : ech}`)"
                            alt="ech"
                        />
                    </div>
                </div>
                <div class="popup__block-form">
                    <form action="/" class="popup__form">
                        <input type="hidden" name="rating" v-model="rating" />
                        <div class="popup__input">
                            <input
                                type="text"
                                name="name"
                                placeholder="Как вас зовут?"
                                maxlength="50"
                                v-model="valueName"
                            />
                        </div>
                        <div class="popup__input textarea">
                            <textarea
                                name="text"
                                id="text"
                                placeholder="Напишите свой отзыв"
                                v-model="valueText"
                            ></textarea>
                        </div>
                        <div class="popup__input">
                            <span class="seven">+7</span>
                            <MaskInput
                                v-model="valuePhone"
                                name="tel"
                                mask="### ### ## ##"
                                placeholder="900 000 00 00"
                            />
                        </div>
                        <div class="popup__agree">
                            <p>Указывая номер телефона, вы принимаете</p>
                            <a href="Пользовательское_соглашение.pdf" target="_blank"
                                >Политику обработки персональных данных</a
                            >
                        </div>
                        <button type="submit" name="send" class="btn send" :disabled="checkForm ? false : true">
                            Отправить
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BtnClose from "../UI/BtnClose.vue";
export default {
    components: { BtnClose },
    data() {
        return {
            isClick: false,
            rating: 0,
            active: 0,
            starLimit: 5,
            ech: "ech.png",
            ech_active: "ech_active.png",
            valueName: "",
            valuePhone: "",
            valueText: "",
        };
    },
    methods: {
        overRatingStar(star) {
            this.active = star;
        },
        setRatingStar(star) {
            console.log(star);
            this.rating = star;
        },
    },
    computed: {
        checkForm() {
            return (
                this.rating > 0 &&
                this.valueName.length >= 2 &&
                this.valueText.length >= 5 &&
                this.valuePhone.length > 12
            );
        },
    },
};
</script>

<style lang="scss">
@import url("@/assets/scss/popup.scss");
.popup {
    &._review {
        .btn-close {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }
    &__raiting {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    &__ech {
        width: 40px;
        height: 40px;
        margin: 0 2.5px;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        @media (max-width: 768px) {
            margin: 0 5px;
        }
    }
    .btn[disabled] {
        background: #c8c8c8;
        color: #7a7a7a;
    }
}
</style>
