<template>
    <div class="main">
        <section class="banner">
            <div class="banner__main">
                <div class="banner__logo"><img src="@/assets/img/svg/logo-white.svg" alt="logo" /></div>
                <router-link to="/menu" class="banner__btn"
                    ><btn-name btnName="Перейти в меню" btnClass="btn-bnw"
                /></router-link>
            </div>
            <div class="banner__mute" @click="isVolume = !isVolume">
                <Volume v-if="isVolume" />
                <Mute v-else />
            </div>
            <div class="banner__video">
                <video width="100%" height="100%" :muted="!isVolume" autoplay loop playsinline>
                    <source src="@/assets/video/main.mp4" type="video/mp4" codecs="mp4" />
                </video>
            </div>
        </section>
        <section class="dishes">
            <div class="dishes__top">
                <div class="container">
                    <div class="dishes__head">
                        <h2 class="title">{{ dishes.title }}</h2>
                        <div class="text" v-html="dishes.text"></div>
                    </div>
                </div>
            </div>
            <div class="dishes__bottom">
                <div class="container">
                    <div class="dishes__authors">
                        <h3 class="dishes__head-title">{{ dishes.head_title }}</h3>
                        <div class="dishes__blocks">
                            <div class="dishes__block big">
                                <div class="dishes__img">
                                    <div class="img">
                                        <img
                                            :src="require(`@/assets/img/dishes/${dishes.blocks[0].img}`)"
                                            alt="dishes"
                                            v-if="dishes.blocks[0].bool"
                                        />
                                        <video
                                            v-else
                                            width="100%"
                                            height="100%"
                                            :poster="require(`@/assets/img/dishes/${dishes.blocks[0].img}`)"
                                            muted
                                            loop
                                            autoplay
                                            playsinline
                                        >
                                            <source
                                                :src="require(`@/assets/video/dishes/${dishes.blocks[0].video}`)"
                                                type="video/mp4"
                                                codecs="mp4"
                                            />
                                        </video>
                                    </div>
                                    <button
                                        class="play"
                                        v-if="dishes.blocks[0].bool"
                                        @click="dishes.blocks[0].bool = false"
                                    >
                                        <img src="@/assets/img/svg/play.svg" alt="play" />
                                    </button>
                                </div>
                                <div
                                    class="dishes__container"
                                    :style="{
                                        backgroundImage:
                                            'url(' + require(`@/assets/img/dishes/${dishes.blocks[0].img}`) + ')',
                                    }"
                                >
                                    <div class="dishes__body">
                                        <h4 class="dishes__title">{{ dishes.blocks[0].title }}</h4>
                                        <div class="dishes__text">
                                            {{ dishes.blocks[0].text }}
                                        </div>
                                        <button
                                            class="dishes__btn btn"
                                            @click="$router.push('/menu'), setCategory(dishes.blocks[0].name)"
                                        >
                                            Заказать
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="small-block">
                                <div class="dishes__block small" v-for="dish in dishes.blocks.slice(1)" :key="dish.id">
                                    <div class="dishes__img">
                                        <div class="img">
                                            <img
                                                :src="require(`@/assets/img/dishes/${dish.img}`)"
                                                alt="dishes"
                                                v-if="dish.bool"
                                            />
                                            <video
                                                v-else
                                                width="100%"
                                                height="100%"
                                                :poster="require(`@/assets/img/dishes/${dish.img}`)"
                                                muted
                                                loop
                                                autoplay
                                                playsinline
                                            >
                                                <source
                                                    :src="require(`@/assets/video/dishes/${dish.video}`)"
                                                    type="video/mp4"
                                                    codecs="mp4"
                                                />
                                            </video>
                                        </div>
                                        <button class="play" v-if="dish.bool" @click="dish.bool = false">
                                            <img src="@/assets/img/svg/play.svg" alt="play" />
                                        </button>
                                    </div>
                                    <div
                                        class="dishes__container"
                                        :style="{
                                            backgroundImage: 'url(' + require(`@/assets/img/dishes/${dish.img}`) + ')',
                                        }"
                                    >
                                        <div class="dishes__body">
                                            <h4 class="dishes__title">{{ dish.title }}</h4>
                                            <div class="dishes__text">
                                                {{ dish.text }}
                                            </div>
                                            <button
                                                class="dishes__btn btn"
                                                @click="$router.push('/menu'), setCategory(dish.name)"
                                            >
                                                Заказать
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <swiper
                            :modules="modules"
                            :slides-per-view="1"
                            :space-between="0"
                            @swiper="onSwiper"
                            @slideChange="onSlideChange"
                            :pagination="{ clickable: true }"
                            :autoplay="{ delay: 2500 }"
                            class="swiper md"
                        >
                            <swiper-slide
                                v-for="slide in slides"
                                :key="slide.id"
                                class="swiper-slide"
                                @click="hrefLink(slide.to), slide.id === 3 ? setCategory(slide.name) : false"
                            >
                                <img :src="require(`@/assets/img/slider/${slide.img}`)" alt="stories" />
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
                <div class="swiper posters mm">
                    <block-posters />
                </div>
            </div>
        </section>
        <section class="map">
            <div class="container">
                <div class="map__container">
                    <div class="map__body">
                        <h2 class="title">Впервые<br />в Казани?</h2>
                        <div class="map__text">
                            <p class="md">Попробуй всю<br />татарскую кухню, а не<br />только эчпочмак!</p>
                            <p class="mm">Попробуй всю татарскую кухню,<br />а не только эчпочмак!</p>
                        </div>
                    </div>
                    <div class="map__img"><img src="@/assets/img/boy.webp" alt="boy" /></div>
                    <div class="map__btn">
                        <button class="btn" @click="$router.push('/tourists')">
                            <span>Заинтриговали</span> <img src="@/assets/img/svg/arrow-green.svg" alt="arrow" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
        <section class="rests">
            <div class="container">
                <div class="rests__container">
                    <h3 class="rests__title">Наши рестораны</h3>
                    <div class="rests__blocks">
                        <div class="rests__block">
                            <div class="rests__body">
                                <div class="rests__img">
                                    <img src="@/assets/img/rests/DSC04249.webp" alt="rests" />
                                </div>
                                <h4 class="rests__address">ул. Кремлевская, 21</h4>
                            </div>
                        </div>
                        <div class="rests__block">
                            <div class="rests__body">
                                <div class="rests__img"><img src="@/assets/img/rests/088.webp" alt="rests" /></div>
                                <h4 class="rests__address">ул. Баумана, 47/9</h4>
                            </div>
                        </div>
                        <div class="rests__block">
                            <div class="rests__bg">
                                <div class="rests__text">
                                    <p>Смотреть</p>
                                    <p>все адреса</p>
                                    <p>ресторанов</p>
                                </div>
                                <router-link to="/restaraunts" class="rests__btn-ref"
                                    ><img src="@/assets/img/svg/arrow-big.svg" alt="arrow"
                                /></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="delivery">
            <div class="delivery__packet1 img"><img src="@/assets/img/delivery/packet1.webp" alt="packet" /></div>
            <div class="delivery__packet2 img"><img src="@/assets/img/delivery/packet2.webp" alt="packet" /></div>
            <div class="delivery__packet3 img"><img src="@/assets/img/delivery/packet3.webp" alt="packet" /></div>
            <div class="container">
                <div class="delivery__container">
                    <h3 class="delivery__title">Если әбика не рядом,<br />заказывай доставку!</h3>
                    <div class="delivery__order">
                        <div class="delivery__blocks">
                            <div class="delivery__block">
                                <div class="delivery__img">
                                    <img src="@/assets/img/svg/dishes.svg" alt="dishes" />
                                </div>
                                <h4 class="delivery__name">Любимые татарские<br />блюда</h4>
                            </div>
                            <div class="delivery__block">
                                <div class="delivery__img"><img src="@/assets/img/svg/home.svg" alt="home" /></div>
                                <h4 class="delivery__name">Готовим вкусно,<br />как дома</h4>
                            </div>
                            <div class="delivery__block">
                                <div class="delivery__img">
                                    <img src="@/assets/img/svg/delivery.svg" alt="delivery" />
                                </div>
                                <h4 class="delivery__name">Доставка<br />от 40 минут</h4>
                            </div>
                        </div>
                        <button class="delivery__btn btn" @click="$router.push('/menu')">Заказать доставку</button>
                    </div>
                    <div class="delivery__subscribe">
                        <div class="delivery__text">
                            <h3 class="delivery__title">Забирай подарок<br />от Тюбетей!</h3>
                            <div class="delivery__subtitle">
                                <p class="md">Подпишись, чтобы получить промокод<br />и быть в курсе наших акций</p>
                                <p class="mm">
                                    Подпишись, чтобы получить<br />промокод и быть в курсе<br />наших акций
                                </p>
                            </div>
                            <a
                                href="https://t.me/Tubeteybot?start=link_gtW1wFpRKh"
                                class="delivery__btn-subscribe"
                                target="_blank"
                                ><span>Подписаться</span> <img src="@/assets/img/svg/Telegram.svg" alt="Telegram"
                            /></a>
                        </div>
                        <div class="delivery__img1 img">
                            <img src="@/assets/img/delivery/percent.webp" alt="percent" class="md" />
                            <img src="@/assets/img/delivery/percent.png" alt="percent" class="mm" />
                        </div>
                        <div class="delivery__img2 img">
                            <img src="@/assets/img/delivery/gift.webp" alt="gift" class="md" />
                            <img src="@/assets/img/delivery/gift.png" alt="gift" class="mm" />
                        </div>
                        <div class="delivery__img3 img">
                            <img src="@/assets/img/delivery/blur_gift.webp" alt="gift" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="reviews">
            <div class="reviews__img1 img"><img src="@/assets/img/icons/smile.webp" alt="smile" /></div>
            <div class="reviews__img2 img"><img src="@/assets/img/icons/smile2.webp" alt="smile" /></div>
            <div class="reviews__img3 img"><img src="@/assets/img/icons/god.webp" alt="god" /></div>
            <div class="reviews__img4 img"><img src="@/assets/img/icons/heart.webp" alt="heart" /></div>
            <div class="reviews__img5 img mm"><img src="@/assets/img/icons/like.webp" alt="like" /></div>
            <div class="container">
                <div class="reviews__container">
                    <h2 class="reviews__title title-h2">Отзывы наших гостей</h2>
                    <div class="reviews__guests">
                        <div
                            class="reviews__person"
                            v-for="(review, index) in reviews"
                            :key="review.id"
                            @click="(isStories = true), setIndexSlide(index)"
                        >
                            <img :src="require(`@/assets/img/reviews/${review.img}`)" alt="reviews" />
                        </div>
                    </div>
                    <div class="reviews__raiting">
                        <div class="reviews__stars small-block">
                            <img src="@/assets/img/icons/star.webp" alt="star" />5,0
                        </div>
                        <div class="reviews__likes small-block">
                            <img src="@/assets/img/icons/like.webp" alt="like" />93,8К
                        </div>
                    </div>
                    <div class="tags">
                        <span class="tag" v-for="(tag, i) in showTags ? tags : tags.slice(0, 10)" :key="i">{{
                            tag
                        }}</span>
                    </div>
                    <div class="reviews__arrow-up" @click="showTags = !showTags">
                        <span>Что еще о нас говорят?</span>
                        <img src="@/assets/img/svg/arrow-up.svg" alt="arrow" v-if="showTags" />
                        <img src="@/assets/img/svg/arrow-down.svg" alt="arrow" v-else />
                    </div>
                    <button class="reviews__btn btn" @click="isReview = true">Оставить свой отзыв</button>
                </div>
            </div>
        </section>
        <section class="company">
            <div class="container">
                <h1 class="company__title title-h2 md">Тюбетей - компания возможностей</h1>
                <h1 class="company__title title-h2 mm">Тюбетей -<br />компания возможностей</h1>
                <div class="company__blocks">
                    <div class="company__block">
                        <div class="company__body">
                            <h3 class="company__head-title">О компании</h3>
                            <div class="company__text">
                                <p>
                                    10 ресторанов Тюбетей, 2 ресторана TATAR в Казани, ресторан ZAMAN в Уфе и свое
                                    производство
                                </p>
                            </div>
                            <button class="company__btn btn-bn" @click="$router.push('/about-us')">Подробнее</button>
                        </div>
                        <div class="company__img"><img src="@/assets/img/3d/flower.webp" alt="company" /></div>
                    </div>
                    <div class="company__block">
                        <div class="company__body">
                            <div class="company__head-title">Вакансии</div>
                            <div class="company__text">
                                <p>
                                    Уже более 400 сотрудников. Ценим каждого, помогаем раскрыть потенциал на максимум!
                                </p>
                            </div>
                            <a href="https://rabota.tubatay.com/"
                                ><button href="https://rabota.tubatay.com/" class="company__btn btn-bn">
                                    Подробнее
                                </button></a
                            >
                        </div>
                        <div class="company__img"><img src="@/assets/img/3d/tubatay.webp" alt="company" /></div>
                    </div>
                    <div class="company__block">
                        <div class="company__body">
                            <div class="company__head-title">Университет</div>
                            <div class="company__text">
                                <p>Совместно с ТИСБИ. Целевое обучение. Бакалавриат для управляющих ресторанами</p>
                            </div>
                            <a href="https://tisbi.tubatay.com/"
                                ><button class="company__btn btn-bn">Подробнее</button></a
                            >
                        </div>
                        <div class="company__img"><img src="@/assets/img/3d/univer.webp" alt="company" /></div>
                    </div>
                    <div class="company__block">
                        <div class="company__body">
                            <div class="company__head-title">Инвесторам</div>
                            <div class="company__text">
                                <p>Ищем партнеров, которым важно инвестировать в чистый бизнес с благими целями</p>
                            </div>
                            <button class="company__btn btn-bn" @click="$router.push('/invest')">Подробнее</button>
                        </div>
                        <div class="company__img"><img src="@/assets/img/3d/hands.webp" alt="company" /></div>
                    </div>
                </div>
            </div>
        </section>
        <stories v-if="isStories" @close="isStories = false" />
        <review v-if="isReview" @close="isReview = false" />
    </div>
</template>

<script>
import Stories from "@/components/popups/Stories.vue";
import Review from "@/components/popups/Review.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import Mute from "@/components/UI/Mute.vue";
import Volume from "@/components/UI/Volume.vue";
import BtnName from "@/components/UI/BtnName.vue";
import BlockPosters from "@/components/BlockPosters.vue";
export default {
    name: "HomeView",
    components: { Stories, Review, Swiper, SwiperSlide, Mute, Volume, BtnName, BlockPosters },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Pagination, Autoplay],
        };
    },
    data() {
        return {
            isVolume: false,
            isStories: false,
            isReview: false,
            showTags: false,
            reviews: [
                { id: 1, img: "Frame1.webp" },
                { id: 2, img: "Frame2.webp" },
                { id: 3, img: "Frame3.webp" },
                { id: 4, img: "1.png" },
                { id: 5, img: "2.png" },
                { id: 6, img: "3.png" },
                { id: 7, img: "4.png" },
            ],
            tags: [
                "Эчпочмаки фри прям в сердечко",
                "Сочная начинка",
                "Атмосфера, как дома",
                "Замечательная сеть из солнечной Казани",
                "Готовят быстро",
                "Приветливые ребята",
                "Готовят с душой, принимают радушно",
                "Приемлемые цены",
                "Современно",
                "Находка для ценителей татарской кухни",
                "Чак-чак прекрасен",
                "В центре города",
                "Место классное",
                "Встречают с улыбкой",
                "К посещению каждому туристу",
                "Чисто, уютно и очень вкусно",
            ],
            dishes: {
                title: `О чем мы\n готовим?`,
                head_title: "Авторские блюда",
                text: `<p>
                            Тюбетей, вдохновленный щедростью местной земли, богатством полей, лесов и лугов
                            Татарстана, знакомит гостей и жителей региона с татарской кухней, раскрывает красоту
                            местной природы посредством вкусов, ароматов и текстур.
                        </p>
                        <p>
                            В 2023 году сеть ресторанов «Тюбетей» была признана лучшей концепцией национальной кухни
                            в России на «Премии быстрых».
                        </p>`,
                blocks: [
                    {
                        id: 1,
                        title: "Кыстыбургер",
                        text: `Татарский бургер, только полезнее и сытнее.\n Выбери свою начинку`,
                        img: "1.png",
                        video: "1.mp4",
                        bool: true,
                        name: "Кыстыбургеры",
                    },
                    {
                        id: 2,
                        title: "Курай",
                        text: `В Татарстане на курае не только играют,\n но и едят его. Попробуйте, почему.`,
                        img: "2.png",
                        video: "2.mp4",
                        bool: true,
                        name: "Кураи",
                    },
                    {
                        id: 3,
                        title: "Өчпочмоти",
                        text: `Равновкусный треугольный десерт из\n рисового теста с начинкой из крем чиза`,
                        img: "3.png",
                        video: "3.mp4",
                        bool: true,
                        name: "Десерты",
                    },
                ],
            },
            slides: [
                {
                    id: 1,
                    img: "1.png",
                    to: "https://eda.yandex.ru/r/tyubetej",
                },
                {
                    id: 2,
                    img: "2.png",
                    to: "https://t.me/TubatayRabotaBot?start=link_piWxNtZIFt",
                },
                {
                    id: 3,
                    img: "3.png",
                    to: "/menu",
                    name: "Десерты",
                },
            ],
        };
    },
    methods: {
        setCategory(name) {
            localStorage.setItem("nameCategory", name);
        },
        hrefLink(to) {
            window.open(to);
        },
        setIndexSlide(index) {
            console.log(index);
            localStorage.setItem("indexSlide", index);
        },
    },
    mounted() {
        localStorage.removeItem("nameCategory");
    },
};
</script>

<style lang="scss">
.main {
    // banner
    .banner {
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 0;
        top: -64px;
        &__video {
            position: absolute;
            width: inherit;
            height: inherit;
            z-index: -1;
            video {
                object-fit: cover;
            }
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: rgb(31 33 32 / 70%);
                width: 100%;
                height: 100%;
            }
        }
        &__mute {
            position: absolute;
            bottom: 90px;
            right: 100px;
            cursor: pointer;
            @media (max-width: 768px) {
                right: 20px;
            }
        }
        &__main {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__logo {
            width: 304px;
            height: 54px;
            @media (max-width: 768px) {
                width: 136px;
                height: 24.11px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &__btn {
            .btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 224px;
                height: 56px;
                border-radius: 30px;
                background: none;
                border: 1px solid #fff;
                font-size: 16px;
                line-height: 24px;
                font-family: "Nunito Sans SemiBold";
                color: #fff;
                margin: 75px auto 0;
                font-family: "Nunito Sans SemiBold";
                @media (max-width: 768px) {
                    width: 160px;
                    height: 48px;
                    border-radius: 24px;
                    font-size: 14px;
                    margin: 100px auto 0;
                    font-family: "Nunito Sans SemiBold";
                }
                &:hover,
                &:focus {
                    background: #000;
                    border: 1px solid #000;
                    transition: 0.2s ease 0.2s;
                }
            }
        }
    }
    // dishes
    .dishes {
        position: relative;
        top: -90px;
        margin-bottom: -90px;
        &__head {
            display: flex;
            justify-content: space-between;
            padding-top: 50px;
            padding-bottom: 50px;
            @media (max-width: 768px) {
                flex-direction: column;
                padding-top: 30px;
                padding-bottom: 30px;
            }
            .title {
                white-space: pre-line;
            }
        }

        &__top {
            border-radius: 24px 24px 0 0;
            overflow: hidden;
            background-color: #fff;
            box-shadow: 0px 3px 35px rgb(0, 45, 29, 0.6);
        }

        &__text {
            font-size: 14px;
            font-family: var(--font-main);
            line-height: 24px;
            color: #1f2120;
            margin-top: 10px;
            white-space: pre-line;
            @media (max-width: 768px) {
                margin-top: 0;
                white-space: normal;
                br {
                    display: none;
                }
            }
        }

        &__bottom {
            background-color: #ecf4ef;
        }

        &__authors {
            padding-top: 50px;
            padding-bottom: 50px;
            @media (max-width: 768px) {
                padding-bottom: 30px;
            }
        }

        &__head-title {
            font-size: 28px;
            font-family: "Nunito Sans Black";
            line-height: 36px;
            color: #1f2120;
            @media (max-width: 768px) {
                font-size: 22px;
                line-height: 32px;
                font-family: "Nunito Sans Black";
            }
        }

        &__blocks {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            @media (max-width: 768px) {
                flex-direction: column;
            }
        }

        &__block {
            border-radius: 24px;
            overflow: hidden;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            @media (max-width: 768px) {
                border-radius: 16px;
            }
        }

        &__img {
            position: relative;
            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__container {
            height: auto;
            min-height: 200px;
            padding: 20px 30px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: bottom;
            position: relative;
            transform: scaleY(-1);
            flex: 1 1 auto;
            @media (max-width: 768px) {
                padding: 20px;
            }
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgb(255, 255, 255, 0.5);
                backdrop-filter: blur(10px);
            }
        }

        &__body {
            position: relative;
            transform: scaleY(-1);
        }

        &__title {
            font-size: 22px;
            font-family: "Nunito Sans ExtraBold";
            line-height: 30px;
            color: #000;
            @media (max-width: 768px) {
                font-size: 18px;
                line-height: 26px;
                color: #1f2120;
                font-family: "Nunito Sans Black";
            }
        }

        &__btn {
            background: var(--green);
            max-width: 224px;
            height: 56px;
            border-radius: 30px;
            font-size: 16px;
            font-family: "Nunito Sans Bold";
            line-height: 24px;
            color: #fff;
            margin-top: 20px;
            &:hover,
            &:focus {
                opacity: 0.9;
                transition: 0.2s ease 0.2s;
            }
            @media (max-width: 768px) {
                width: 160px;
                height: 48px;
                border-radius: 24px;
                font-size: 14px;
                margin: 20px 0 0;
                font-family: "Nunito Sans SemiBold";
            }
        }
        .title {
            color: var(--green);
            @media (max-width: 768px) {
                color: #1f2120;
            }
        }
        .text {
            font-size: 16px;
            font-family: var(--font-main);
            line-height: 24px;
            color: #000;
            padding-left: 100px;
            p {
                padding-top: 10px;
            }
            @media (max-width: 768px) {
                padding: 0;
                font-size: 14px;
                color: #1f2120;
            }
        }
        .img {
            overflow: hidden;
        }
        .big {
            width: 58%;
            box-shadow: 0px 3.15px 36.71px rgb(0, 45, 29, 0.05);
            .img {
                height: 604px;
            }
            @media (max-width: 768px) {
                width: 100%;
                .img {
                    height: 360px;
                }
            }
        }
        .small-block {
            width: 40%;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
        .small {
            box-shadow: 0px 3px 35px rgb(0, 45, 29, 0.05);
            .img {
                height: 192px;
                @media (max-width: 768px) {
                    height: 176px;
                }
            }
        }
        .play {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: none;
            width: 62.24px;
            height: 62.24px;
            margin: auto;
            &:hover,
            &:focus {
                opacity: 0.5;
                transition: 0.2s ease 0.2s;
            }
        }

        .swiper {
            width: 100%;
            height: 100%;
            margin-top: 60px;
            padding-bottom: 40px;
            @media (max-width: 768px) {
                margin-top: 0;
                margin-right: 0;
                margin-left: 0;
                overflow: unset;
                padding-bottom: 0;
            }
        }

        .swiper.posters {
            padding: 0 0 40px 20px;
        }
        .swiper.posters .posters__img {
            min-width: 96px;
            max-width: 96px;
            margin: 0 2.5px;
            padding: 2.5px;
        }

        .swiper-slide {
            height: 272px;
            border-radius: 24px;
            overflow: hidden;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media (max-width: 768px) {
                height: 120px;
                border-radius: 12px;
            }
        }
        .swiper-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            .swiper-pagination-bullet {
                width: 6.67px;
                height: 6.67px;
                background: var(--green);
            }
            .swiper-pagination-bullet-active {
                width: 10px;
                height: 10px;
            }
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
    // map
    .map {
        background-color: var(--green);
        overflow: hidden;
        position: relative;
        height: 376px;
        @media (max-width: 768px) {
            height: auto;
        }
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url("~@/assets/img/map.webp");
            background-repeat: no-repeat;
            background-size: cover;
            filter: brightness(0.3);
            @media (max-width: 768px) {
                background-image: url("~@/assets/img/map-m.png");
                background-position-x: right;
            }
        }
        &__container {
            display: flex;
            align-items: center;
            height: 376px;
            position: relative;
            @media (max-width: 768px) {
                flex-direction: column;
                height: auto;
                padding-top: 50px;
            }
        }

        &__body {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__text {
            font-size: 22px;
            font-family: "Nunito Sans Bold";
            line-height: 30px;
            color: #fff;
            margin-top: 35px;
            @media (max-width: 768px) {
                font-size: 14px;
                font-family: var(--font-main);
                line-height: 24px;
                margin-top: 20px;
                text-align: center;
                display: flex;
                justify-content: center;
                margin-top: 10px;
            }
        }

        &__img {
            width: 464px;
            height: 603px;
            margin-top: -50px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__btn {
            display: flex;
            align-items: flex-end;
            height: 100%;
            padding-bottom: 65px;
            @media (max-width: 768px) {
                position: absolute;
                bottom: 0;
                padding-bottom: 50px;
            }
        }
        .title {
            color: #fff;
        }
        .btn {
            background: #fff;
            color: var(--green);
            font-family: "Nunito Sans Bold";
            span {
                margin-right: 10px;
            }
        }
    }
    // rests
    .rests {
        padding: 60px 0px 90px 0px;
        @media (max-width: 768px) {
            padding: 50px 0 20px;
        }

        &__title {
            font-size: 28px;
            font-family: "Nunito Sans Black";
            line-height: 36px;
            color: #1f2120;
            @media (max-width: 768px) {
                font-size: 22px;
                line-height: 32px;
            }
        }

        &__blocks {
            display: flex;
            justify-content: space-between;
            margin: 0 -12px;
            @media (max-width: 768px) {
                overflow-y: hidden;
                margin: 0 -10px;
            }
        }

        &__block {
            flex: 1 1 33.33%;
            margin: 20px 12px 0px;
            @media (max-width: 768px) {
                flex: 0 0 40%;
                margin: 0 10px;
                padding: 30px 0;
            }
            @media (max-width: 480px) {
                flex: 0 0 70%;
            }
        }

        &__body {
            border-radius: 16px;
            overflow: hidden;
            box-shadow: 0 3px 35px rgb(0, 45, 29, 0.1);
        }

        &__img {
            height: 305px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__address {
            font-size: 16px;
            font-family: "Nunito Sans Bold";
            line-height: 24px;
            color: var(--green);
            height: 56px;
            display: flex;
            align-items: center;
            padding: 0 20px;
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }

        &__bg {
            background-color: var(--green);
            box-shadow: 0 3px 35px rgb(0, 45, 29, 0.6);
            border-radius: 16px;
            overflow: hidden;
            height: 100%;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &__text {
            font-size: 22px;
            font-family: "Nunito Sans Bold";
            line-height: 30px;
            text-align: center;
            color: #fff;
            @media (max-width: 768px) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        &__btn-ref {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
            @media (max-width: 768px) {
                width: 50px;
                height: 50px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    padding: 10px;
                }
            }
        }
    }
    // delivery
    .delivery {
        background-color: #ecf4ef;
        overflow: hidden;
        padding: 60px 0;
        position: relative;
        @media (max-width: 768px) {
            padding: 50px 0 0;
        }
        &__packet1 {
            width: 390.56px;
            height: 424.04px;
            top: -18%;
            left: -7%;
            transform: rotate(20deg);
            @media (max-width: 768px) {
                width: 246.62px;
                height: 267.76px;
                top: 5rem;
                left: -8.5rem;
                transform: rotate(30deg);
            }
        }

        &__packet2 {
            width: 502px;
            height: 543px;
            top: -5%;
            right: -5%;
            @media (max-width: 768px) {
                width: 272px;
                height: 294px;
                top: 23%;
                right: -6.5rem;
            }
        }

        &__packet3 {
            width: 385px;
            height: 376.63px;
            bottom: 38%;
            left: 0;
            transform: scaleX(-1);
            @media (max-width: 768px) {
                width: 230px;
                height: 225px;
                left: -6rem;
                bottom: 35%;
            }
        }

        &__container {
            position: relative;
            z-index: 1;
            .delivery__title {
                color: var(--green);
                text-align: center;
            }
        }

        &__title {
            font-size: 28px;
            font-family: "Nunito Sans Black";
            line-height: 36px;
            @media (max-width: 768px) {
                font-size: 22px;
                line-height: 32px;
                font-family: "Nunito Sans Bold";
            }
        }

        &__order {
            padding-bottom: 70px;
            @media (max-width: 768px) {
                padding-bottom: 40px;
            }
        }

        &__blocks {
            display: flex;
            @media (max-width: 768px) {
                flex-direction: column;
            }
        }

        &__block {
            flex: 0 0 33.33%;
            margin-top: 50px;
            @media (max-width: 768px) {
                margin-top: 30px;
                text-align: center;
            }
        }

        &__img {
            background-color: rgb(153, 207, 171, 0.2);
            width: 110px;
            height: 110px;
            border-radius: 50%;
            overflow: hidden;
            padding: 1.5rem;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            @media (max-width: 768px) {
                width: 84px;
                height: 84px;
            }
        }

        &__name {
            font-size: 16px;
            font-family: var(--font-main);
            line-height: 24px;
            text-align: center;
            color: #1f2120;
            margin-top: 25px;
            font-family: var(--font-main);
            @media (max-width: 768px) {
                display: ruby-text;
                font-size: 14px;
            }
        }

        &__btn {
            max-width: 256px;
            margin: 50px auto 0;
            font-family: "Nunito Sans Bold";
            @media (max-width: 768px) {
                font-family: "Nunito Sans SemiBold";
            }
        }

        &__subscribe {
            background-color: var(--green);
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            .delivery__title {
                color: #fff;
                text-align: left;
            }
            @media (max-width: 768px) {
                border-radius: 0;
                margin: 0 -20px;
            }
        }

        &__text {
            padding: 60px 80px;
            position: relative;
            z-index: 1;
            @media (max-width: 768px) {
                padding: 40px 20px;
            }
        }

        &__subtitle {
            font-size: 16px;
            font-family: var(--font-main);
            line-height: 24px;
            color: #ecf4ef;
            margin-top: 10px;
            font-family: var(--font-main);
            @media (max-width: 768px) {
                font-size: 14px;
                font-family: "Nunito Sans ExtraLight";
            }
        }

        &__btn-subscribe {
            width: 224px;
            height: 56px;
            border-radius: 30px;
            border: 1px solid #fff;
            font-size: 16px;
            font-family: "Nunito Sans Bold";
            line-height: 24px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-family: "Nunito Sans SemiBold";
            span {
                margin-right: 5px;
            }
            &:hover,
            &:focus {
                opacity: 0.5;
                transition: 0.2s ease 0.2s;
            }
            @media (max-width: 768px) {
                width: 160px;
                height: 48px;
                border-radius: 24px;
                font-size: 14px;
                margin-top: 25px;
            }
        }

        &__img1 {
            width: 250px;
            top: 0;
            right: 15%;
            @media (max-width: 768px) {
                width: 115px;
                right: 4px;
                top: -60px;
            }
        }

        &__img2 {
            width: 400px;
            bottom: 0;
            right: 20%;
            @media (max-width: 768px) {
                width: 191.43px;
                bottom: -10px;
                right: 0;
            }
        }

        &__img3 {
            width: 250px;
            right: 0;
            bottom: 0;
            @media (max-width: 768px) {
                display: none;
            }
        }

        .img {
            position: absolute;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: drop-shadow(26px 28px 30px rgb(0, 45, 29, 0.4));
                @media (max-width: 768px) {
                    object-fit: contain;
                }
            }
        }
    }
    // reviews
    .reviews {
        padding: 50px 0;
        position: relative;
        @media (max-width: 768px) {
            padding: 35px 0;
        }
        &__img1 {
            top: 100px;
            left: 80px;
            width: 179.27px;
            height: 179.27px;
            @media (max-width: 768px) {
                top: 200px;
                left: -30px;
                width: 82px;
                height: 82px;
            }
        }

        &__img2 {
            top: 200px;
            right: 50px;
            width: 179.39px;
            height: 179.39px;
            @media (max-width: 768px) {
                top: 250px;
                right: -30px;
                width: 59px;
                height: 59px;
            }
        }

        &__img3 {
            bottom: 200px;
            right: 200px;
            width: 120.63px;
            height: 120.63px;
            @media (max-width: 768px) {
                bottom: 50%;
                right: 0;
                width: 36px;
                height: 36px;
            }
        }

        &__img4 {
            bottom: 300px;
            left: 100px;
            width: 114.16px;
            height: 114.16px;
            @media (max-width: 768px) {
                bottom: 50%;
                left: -20px;
                width: 50px;
                height: 50px;
            }
        }
        &__img5 {
            bottom: 100px;
            left: -15px;
            width: 71.61px;
            height: 71.61px;
        }

        &__title {
            text-align: center;
        }

        &__guests {
            display: flex;
            justify-content: center;
            @media (max-width: 768px) {
                justify-content: flex-start;
                overflow-y: hidden;
                margin-right: -20px;
            }
        }

        &__person {
            min-width: 82px;
            max-width: 82px;
            height: 82px;
            border-radius: 50%;
            overflow: hidden;
            background: linear-gradient(90deg, rgba(255, 150, 117, 1) 100%, rgba(214, 107, 81, 1) 100%);
            padding: 4px;
            position: relative;
            margin: 40px 5px 0;
            cursor: pointer;
            @media (max-width: 768px) {
                margin: 20px 5px 0;
            }
            &::before {
                content: "";
                position: absolute;
                width: 78px;
                height: 78px;
                min-width: 78px;
                max-width: 78px;
                background: #fff;
                border-radius: inherit;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
                border-radius: inherit;
            }
        }

        &__raiting {
            display: flex;
            justify-content: center;
            margin-top: 40px;
            @media (max-width: 768px) {
                margin-top: 20px;
            }
        }

        &__arrow-up {
            font-size: 14px;
            font-family: var(--font-main);
            line-height: 24px;
            text-align: center;
            color: #c8c8c8;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            cursor: pointer;
            span {
                padding-right: 5px;
            }
            @media (max-width: 768px) {
                font-size: 12px;
                font-family: "Nunito Sans ExtraLight";
                line-height: 16px;
            }
        }

        &__btn {
            margin: 30px auto 0;
            font-family: "Nunito Sans SemiBold";
        }
        .img {
            position: absolute;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .small-block {
            width: fit-content;
            height: 40px;
            border-radius: 20px;
            background: #ecf4ef;
            font-size: 22px;
            font-family: "Nunito Sans ExtraBold";
            line-height: 30px;
            color: var(--green);
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 10px;
            padding: 0 20px;
            @media (max-width: 768px) {
                height: 32px;
                font-size: 14px;
                font-family: "Nunito Sans Bold";
                line-height: 24px;
                margin: 0 5px;
            }
            img {
                width: 26px;
                height: 26px;
                margin-right: 5px;
                @media (max-width: 768px) {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .tags {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 20px;
            @media (max-width: 768px) {
                margin: 10px auto;
            }
        }
        .tag {
            width: fit-content;
            height: 32px;
            border-radius: 20px;
            background: #f4f4f4;
            text-align: center;
            color: #3b7351;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            padding: 0 10px;
            display: flex;
            align-items: center;
            margin: 10px;
            font-family: var(--font-main);
            @media (max-width: 768px) {
                font-size: 14px;
            }
            @media (max-width: 480px) {
                margin: 5px;
                font-size: 12px;
            }
        }
    }
    // company
    .company {
        background-color: #ecf4ef;
        padding: 50px 0 60px;
        @media (max-width: 768px) {
            padding: 40px 0;
        }
        &__title {
            text-align: center;
            @media (max-width: 768px) {
                text-align: left;
            }
        }

        &__blocks {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &__block {
            background-color: #fff;
            flex: 0 0 49%;
            border-radius: 16px;
            overflow: hidden;
            box-shadow: 0 3px 35px 0 rgb(0, 45, 29, 0.01);
            margin-top: 20px;
            position: relative;
            @media (max-width: 768px) {
                flex: 1 1 auto;
            }
        }

        &__body {
            padding: 30px;
            position: relative;
            z-index: 1;
            width: 70%;
            @media (max-width: 768px) {
                padding: 20px;
            }
        }

        &__head-title {
            font-size: 22px;
            font-family: "Nunito Sans ExtraBold";
            line-height: 30px;
            color: #1f2120;
            @media (max-width: 768px) {
                font-size: 18px;
                line-height: 26px;
                font-family: "Nunito Sans Black";
            }
        }

        &__text {
            font-size: 14px;
            line-height: 24px;
            color: #7a7a7a;
            margin-top: 10px;
            font-family: var(--font-main);
            @media (max-width: 768px) {
                font-size: 12px;
                font-family: "Nunito Sans ExtraLight";
                line-height: 16px;
                font-family: "Nunito Sans ExtraLight";
            }
        }

        &__btn {
            margin-top: 20px;
            font-family: "Nunito Sans Bold";
        }

        &__img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 180px;
            height: 180px;
            @media (max-width: 480px) {
                width: 150px;
                height: 150px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
</style>
