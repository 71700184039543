<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="popup _stories" @click="$emit('close')">
        <div class="popupInner" @click.stop>
            <div class="swiper">
                <btn-close @click="$emit('close')" />
                <swiper
                    :modules="modules"
                    :slides-per-view="1"
                    :space-between="0"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    :scrollbar="{ draggable: true }"
                    :autoplay="{ delay: 2500 }"
                    class="stories"
                >
                    <swiper-slide v-for="slide in slides" :key="slide.id" class="img">
                        <img :src="require(`@/assets/img/stories/${slide.img}`)" alt="stories" />
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Scrollbar, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import BtnClose from "../UI/BtnClose.vue";
export default {
    components: { Swiper, SwiperSlide, BtnClose },
    data() {
        return {
            slides: [
                { id: 1, img: "1.jpg" },
                { id: 2, img: "2.jpg" },
                { id: 3, img: "3.jpg" },
                { id: 4, img: "4.jpg" },
                { id: 5, img: "5.jpg" },
                { id: 6, img: "6.jpg" },
                { id: 7, img: "7.jpg" },
            ],
        };
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
            swiper.slideTo(localStorage.getItem("indexSlide"));
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Scrollbar, Autoplay],
        };
    },
};
</script>

<style lang="scss">
@import url("@/assets/scss/popup.scss");
.popup._stories {
    align-items: center;
    .btn-close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .popupInner {
        max-width: 312px;
        height: 552px;
        @media (max-width: 768px) {
            border-radius: 16px;
        }
    }
    .stories {
        display: flex;
        z-index: -1;
        .img {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media (max-width: 768px) {
                height: 552px;
            }
        }
    }
    .swiper-horizontal > .swiper-scrollbar {
        left: 15px;
        bottom: 0;
        z-index: 1;
        height: 1px;
        width: 75%;
        top: 30px;
        background: rgb(255, 255, 255, 0.2);
    }
    .swiper-scrollbar-drag {
        background: #fff;
        border-radius: 1px;
    }
}
.popup._stories .stories .img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.popup._stories .swiper-horizontal > .swiper-scrollbar {
    left: 15px;
    bottom: 0;
    z-index: 1;
    height: 1px;
    width: 75%;
    top: 30px;
    background: rgba(255, 255, 255, 0.2);
}
.popup._stories .swiper-scrollbar-drag {
    background: #fff;
    border-radius: 1px;
}
</style>
