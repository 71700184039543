import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/style.css";
import { MaskInput } from "vue-3-mask";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import VueTheMask from "vue-the-mask";

const app = createApp(App);

app.use(router)
    .component("MaskInput", MaskInput)
    .component("VueDatePicker", VueDatePicker)
    .use(VueTheMask)
    .mount("#app");
