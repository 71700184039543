<template>
    <div class="steps-order">
        <div class="steps-order__step" v-for="step in steps" :key="step.id" :class="step.id === status ? 'active' : ''">
            <div class="steps-order__step-img">
                <img :src="require(`@/assets/img/svg/${step.img}`)" alt="step" />
            </div>
            <h4 class="steps-order__step-name">{{ step.name }}</h4>
        </div>
    </div>
</template>

<script>
export default {
    props: ["status"],
    data() {
        return {
            steps: [
                { id: 1, name: "В обработке", img: "points.svg" },
                { id: 2, name: "Готовим", img: "cooking.svg" },
                { id: 3, name: "Можно забирать", img: "tableware.svg" },
                { id: 4, name: "Выдан", img: "check-done.svg" },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.steps-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    position: relative;
    overflow: hidden;
    &::before {
        content: "";
        position: absolute;
        top: -1rem;
        bottom: 0;
        right: -1rem;
        left: 0;
        width: 100%;
        height: 1px;
        background: #c8c8c8;
        margin: auto;
    }

    &__step {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__step-img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #c8c8c8;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__step-name {
        font-family: "Nunito Sans ExtraLight";
        font-size: 8px;
        line-height: 12px;
        color: #7a7a7a;
        text-align: center;
        margin-top: 5px;
    }
    .active {
        .steps-order__step-img {
            background: var(--green);
        }
        .steps-order__step-name {
            color: var(--green);
        }
    }
}
</style>
