<template>
    <div
        class="lang"
        :class="$route.path === '/' ? 'text-white' : ''"
        @mouseover="isItems = true"
        @mouseleave="isItems = false"
        :style="isItems ? 'overflow:unset' : 'overflow:hidden'"
    >
        <div class="select-lang">
            <input type="text" name="lang" v-model="langValue" disabled="disabled" />
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" class="md">
                <path d="M1 1.5L5 4.5L9 1.5" :stroke="$route.path === '/' ? '#fff' : 'var(--green)'" stroke-width="2" />
            </svg>
            <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg" class="mm">
                <path d="M1 1L5 4L9 1" :stroke="$route.path === '/' ? '#fff' : 'var(--green)'" />
            </svg>
        </div>
        <ul class="list-lang">
            <li
                class="list-lang-item"
                v-for="lang in langs"
                :key="lang.id"
                @click="(langValue = lang.name), (isItems = false)"
            >
                {{ langValue === lang.name ? null : lang.name }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isItems: false,
            langValue: "РУС",
            langs: [
                { id: 1, name: "РУС" },
                { id: 2, name: "ТАТ" },
                { id: 3, name: "ENG" },
            ],
        };
    },
};
</script>

<style scoped>
.lang {
    font-size: 14px;
    line-height: 22px;
    color: var(--green);
    font-family: var(--font-main);
    position: relative;
    height: auto;
    overflow: hidden;
    flex: 0 0 auto;
    visibility: hidden;
}
.lang:hover svg,
.lang:focus svg {
    transform: scaleY(-1);
    transition: 0.2s ease 0.2s;
}
.lang .select-lang {
    display: flex;
    align-items: center;
}
@media (max-width: 768px) {
    .lang .select-lang {
        align-items: baseline;
    }
    .lang .select-lang svg path {
        stroke: #1f2120;
    }
}
.lang .select-lang input {
    max-width: 40px;
    background: none;
    color: inherit;
    font-family: var(--font-main);
    cursor: pointer;
}
@media (max-width: 768px) {
    .lang .select-lang input {
        font-family: "Nunito Sans ExtraLight";
        max-width: 30px;
        color: #1f2120;
    }
}
.lang .list-lang {
    position: absolute;
}
.lang .list-lang li {
    margin-top: 1.5rem;
    cursor: pointer;
}
.lang .list-lang li:hover,
.lang .list-lang li:focus {
    opacity: 0.5;
    transition: 0.2s ease 0.2s;
}
.lang.text-white {
    color: #fff;
}
.lang.text-white input {
    color: #fff;
}
.lang.text-white svg path {
    stroke: #fff;
}
</style>
