<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="popup _alert" @click="$emit('close'), $emit('showModal')">
        <div class="popupInner" @click.stop>
            <btn-close @click="$emit('close'), $emit('showModal')" />
            <div class="popup__body">
                <h2 class="popup__title">{{ title }}</h2>
                <div class="popup__img"><img src="@/assets/img/3d/alert.png" alt="img" /></div>
                <div class="popup__message">
                    {{ message }}
                </div>
                <btn-name btnName="Хорошо" @click="$emit('close'), $emit('showModal'), setAlert()" />
            </div>
        </div>
    </div>
</template>

<script>
import BtnClose from "../UI/BtnClose.vue";
import BtnName from "../UI/BtnName.vue";
export default {
    components: { BtnClose, BtnName },
    props: ["title", "message"],
    methods: {
        setAlert() {
            localStorage.setItem("alert", true);
        },
    },
};
</script>

<style lang="scss" scoped>
.popup {
    &._alert {
        .btn-close {
            position: absolute;
            top: 15px;
            right: 15px;
        }
        .popup__img {
            width: 188.39px;
            height: 161.4px;
            margin-top: 60px;
        }
        .popup__message {
            margin-top: 30px;
        }
        .btn {
            margin: 50px auto 15px;
        }
    }
}
</style>
