<template>
    <swiper
        :slides-per-view="6"
        :space-between="0"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        class="posters__container"
    >
        <swiper-slide
            class="posters__img"
            v-for="poster in posters"
            :key="poster.id"
            :class="poster.br === true ? 'br' : ''"
            @click="
                poster.id === 2
                    ? hrefOpen(poster.to)
                    : poster.id === 3
                    ? setCategory(poster.to)
                    : $router.push(`${poster.to}`)
            "
        >
            <img :src="require(`@/assets/img/posters/${poster.img}`)" alt="posters" />
        </swiper-slide>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            posters: [
                { id: 1, img: "1.webp", br: true, to: "/discounts" },
                { id: 2, img: "2.webp", br: true, to: "https://loyalty.tubatay.com/" },
                { id: 3, img: "4.webp", br: false, to: "Сувениры" },
            ],
        };
    },
    methods: {
        hrefOpen(to) {
            window.open(to);
        },
        setCategory(name) {
            localStorage.setItem("nameCategory", name);
            this.$route.path === "/" ? this.$router.push("/menu") : location.reload("/menu");
        },
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
        };
    },
};
</script>

<style lang="scss" scoped></style>
