<template>
    <div class="cart md" id="cart-btn">
        <span class="count">{{ totalCountCart() }}</span>
        <button class="btn">Корзина</button>
    </div>
</template>

<script>
export default {
    methods: {
        totalCountCart() {
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            let items = [];
            if (array) {
                for (let i = 0; i < array.length; i++) {
                    if (array[i].type !== "discounted") {
                        items.push(array[i]);
                    }
                }
            }
            return items.reduce((acc, item) => acc + item.count, 0);
        },
    },
};
</script>

<style lang="scss" scoped></style>
