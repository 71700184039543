<template>
    <div class="mobile _mask" :class="{ _show: isMobile }" id="mobile">
        <div class="mobile__block" :class="{ '_active-move': isMobile }">
            <div class="mobile__close">
                <lang-select />
                <div class="mobile__btn-close" @click="$emit('close')">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.10547 1.10535L12.8949 12.8948M12.8949 1.10535L1.10547 12.8948"
                            stroke="#fff"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </svg>
                </div>
            </div>
            <div class="mobile__line"></div>
            <div class="mobile__menu">
                <ul>
                    <template v-if="$route.path === '/menu'">
                        <button
                            class="user-icon"
                            @click="token || userPhone ? authProfile() : $emit('closeLoginModal')"
                        >
                            <user-icon />
                            <span>{{ token ? (in_sign = "Профиль") : (in_sign = "Войти") }}</span>
                        </button>
                        <li v-for="page in pages2" :key="page.id">
                            <template v-if="page.id === 1">
                                <a :href="page.to" class="mobile__menu-item">{{ page.name }}</a>
                            </template>
                            <template v-else>
                                <RouterLink
                                    :to="page.to"
                                    activeClass="active"
                                    exactActiveClass="active"
                                    class="mobile__menu-item"
                                    @click="$emit('close')"
                                    >{{ page.name }}</RouterLink
                                >
                            </template>
                        </li>
                        <div class="logo-icon">
                            <logo-icon />
                        </div>
                    </template>
                    <template v-else>
                        <li v-for="page in pages" :key="page.id">
                            <template v-if="page.name === 'Вакансии' || page.name === 'Университет'">
                                <a :href="page.to" class="mobile__menu-item">{{ page.name }}</a>
                            </template>
                            <template v-else>
                                <RouterLink
                                    :to="page.to"
                                    activeClass="active"
                                    exactActiveClass="active"
                                    class="mobile__menu-item"
                                    @click="$emit('close')"
                                    >{{ page.name }}</RouterLink
                                >
                            </template>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import LangSelect from "./UI/LangSelect.vue";
import LogoIcon from "./UI/LogoIcon.vue";
import UserIcon from "./UI/UserIcon.vue";
export default {
    components: { UserIcon, LogoIcon, LangSelect },
    props: ["isMobile"],
    data() {
        return {
            token: localStorage.getItem("token"),
            userPhone: localStorage.getItem("phone"),
            in_sign: "Войти",
            pages: [
                { id: 1, name: "Акции", to: "/stocks" },
                { id: 2, name: "Туристам", to: "/tourists" },
                { id: 3, name: "Рестораны", to: "/restaraunts" },
                { id: 4, name: "Доставка", to: "/menu" },
                { id: 5, name: "О нас", to: "/about-us" },
                { id: 6, name: "Вакансии", to: "https://rabota.tubatay.com/" },
                { id: 7, name: "Университет", to: "https://tisbi.tubatay.com/" },
                { id: 8, name: "Контакты", to: "/contacts" },
            ],
            pages2: [
                { id: 1, name: "Подробнее про Т-коины", to: "https://loyalty.tubatay.com/" },
                { id: 2, name: "Скачать приложение", to: "/discounts" },
                { id: 3, name: "Условия доставки", to: "/delivery" },
                { id: 4, name: "Рестораны", to: "/restaraunts" },
                { id: 5, name: "Контакты", to: "/contacts" },
                { id: 6, name: "На главную", to: "/" },
            ],
        };
    },
    methods: {
        authProfile() {
            this.$router.push("/profile");
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss">
#mobile {
    .logo-icon {
        width: 48px;
        height: 48px;
        margin-top: 30px;
    }
    .logo-icon svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .user-icon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: none;
    }
    .user-icon svg {
        margin-right: 10px;
    }
    .mobile__block {
        width: 100%;
        max-width: 500px;
        height: 100vh;
        background-color: var(--green);
        position: absolute;
        z-index: 999;
        left: -100%;
        top: 0;
        display: flex;
        flex-direction: column;
        opacity: 0;
    }
    .mobile__close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        border-bottom: 1px solid rgb(236, 244, 239, 0.2);
    }
    .mobile__close .lang .list-lang li,
    .mobile__close .lang .select-lang input {
        color: #fff;
    }
    .mobile__close .lang .select-lang svg path {
        stroke: #fff;
        fill: #fff;
    }
    .mobile__btn-close {
        padding: 5px;
    }
    .mobile__line {
        width: 100%;
        height: 1px;
        background: rgba(236, 244, 239, 0.2);
    }
    .mobile__menu {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 20px 30px 80px;
        height: 100%;
    }
    .mobile__menu ul {
        flex: 1 1 auto;
        margin-top: 1rem;
        word-break: break-word;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .user-icon,
    .mobile__menu ul li {
        padding: 20px 0;
        text-align: right;
        font-size: 18px;
        font-weight: 800;
        line-height: 26px;
        color: #fff;
        font-family: "Nunito Sans Bold";
    }
    .mobile__menu ul li a {
        color: inherit;
    }
    .mobile__menu-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .mobile__menu-item img[alt="user"] {
        width: 16px;
        height: 20px;
        margin-right: 10px;
    }
    .mobile__logo {
        width: 48px;
        height: 48px;
        margin-top: 30px;
    }
    .mobile__logo img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
</style>
